import axios from "axios";
import { INotification } from "../../../shared/models/INotification";

//This is the WebApp backend API.
const notificationsUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/notifications`;

export const getLatestNotifications = async () => {
  const response = await axios.get(`${notificationsUrl}/latest`);
  return response.data;
};

export const getNotifications = async (skip: number, take: number) => {
  const response = await axios.get(
    `${notificationsUrl}?skip=${skip}&take=${take}`
  );
  return response.data;
};

export const getNotificationById = async (notificationId: string) => {
  const response = await axios.get(`${notificationsUrl}/${notificationId}`);
  return response.data;
};

export const createNotification = async (notification: INotification) => {
  const response = await axios.post(notificationsUrl, notification);
  return response.data;
};
