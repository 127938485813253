import moment from "moment";

export const isNullOrUndefinedOrWhiteSpace = (
  value: string | null | undefined
): boolean => {
  return value === null || value === undefined || value.trim() === "";
};

export const isValidEmail = (value: string | null | undefined): boolean => {
  if (value === null || value === undefined) return false;
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value);
};

export const isValidURL = (value: string | null | undefined): boolean => {
  if (value === null || value === undefined) return false;
  return /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi.test(
    value
  );
};

//this method is for formik input type date fields
//the formik in FMS form either takes a ISO string (yyyy-mm-dd) or a empty string
export const utcToISODateString = (
  value: Date | string | null | undefined
): string => {
  if (value === "" || value === null || value === undefined) return "";

  return new Date(value).toISOString().slice(0, 10);
};

export const utcToDateString = (
  value: Date | string | null | undefined
): string => {
  if (value === "" || value === null || value === undefined) return "";

  const d = new Date(value).toISOString().slice(0, 10);
  return moment(d).format("MM/DD/YYYY");
};

export const utcToDateStringWithTime = (
  value: Date | string | null | undefined
): string => {
  if (value === "" || value === null || value === undefined) return "";

  return moment(value).format("MM/DD/YYYY hh:mm:ss A");
};

export const getUniqueItemsOfArray = (array: string[]) => {
  return array
    .filter((e, i) => {
      return array.indexOf(e) === i;
    })
    .sort((a, b) => a.localeCompare(b));
};
