import React from "react";
import "./App.css";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import AppRoutes from "./components/routing/RouteWrapper";
import AxiosInit from "./components/AxiosInit";

export const App: React.FC = (props) => {
  // This is required for React-Router - useNavigate hook uses history under the hood
  createBrowserHistory();



  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        pauseOnHover={true}
        pauseOnFocusLoss={true}
      ></ToastContainer>
      <BrowserRouter>
        <AxiosInit></AxiosInit>
        <AppRoutes></AppRoutes>
      </BrowserRouter>
    </>
  );
};

export default App;
