import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IUser } from "../../../../../shared/models/IUser";

// Define a service using a base URL and expected endpoints
export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: fdot.process.env.BACKEND_SERVER_HOST,
    credentials: "include",
  }),
  tagTypes: ["User"],
  endpoints: (builder) => ({
    getUser: builder.query<IUser, void>({
      query: () => `api/user`,
      providesTags: ["User"],
    }),
    impersonate: builder.mutation<IUser, string>({
      query: (azureAdOid) => ({
        url: `api/impersonate`,
        method: "POST",
        body:  { azureAdOid },
      }),
      invalidatesTags: ["User"],
    }),
  }),
});

export const {
  useGetUserQuery,
  useImpersonateMutation,
} = userApi;
