import * as configApi from '../../apis/ConfigApi';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppConfig } from "../../../../shared/models/configs/IAppConfig";

type SliceState = {
    loadingConfig: boolean;
    config: IAppConfig | null;
}

const configSlice = createSlice({
    name: 'app-config',
    initialState: {
        loadingConfig: false,
        config: null
    } as SliceState,
    reducers: {
        updateConfig: (state: SliceState, action: PayloadAction<IAppConfig>) => {
            state.config = {
                APPINSIGHTS_INSTRUMENTATIONKEY: action.payload.APPINSIGHTS_INSTRUMENTATIONKEY,
                ARCULUS_API_KEY: action.payload.ARCULUS_API_KEY,
                ARCULUS_API_BASE_URL: action.payload.ARCULUS_API_BASE_URL,
                ENVIRONMENT: action.payload.ENVIRONMENT
            }
            return state;
        },
        loadingStarted: (state: SliceState) => {
            state.loadingConfig = true;
            return state;
        },
        loadingCompleted: (state: SliceState) => {
            state.loadingConfig = false;
            return state;
        }
    }
});

export const { updateConfig, loadingStarted, loadingCompleted } = configSlice.actions;
export default configSlice.reducer;

export const fetchConfig = () => async (dispatch: any) => {
    dispatch(loadingStarted());
    configApi.getConfig()
        .then(
            (config: IAppConfig) => {
                dispatch(updateConfig(config));
                dispatch(loadingCompleted());
            })
        .catch((error: any) => {
            console.log(error);
            dispatch(loadingCompleted())
        });
}
