import moment from "moment";
import { IEvent } from "../../../../shared/models/IEvent";
import { IEventGridModel } from "../../models/Events/IEventGridModel";
import { INotification } from "../../../../shared/models/INotification";
import { INotificationGridModel } from "../../models/Events/INotificationGridModel";

const momentDateTimeFormat = "MM/DD/YYYY hh:mm:ss A";


export const mapEventsGridData = (events: Array<IEvent>): Array<IEventGridModel> => {
    let mappedEvents = events.map((eve) => ({
        ...eve,
        formattedCreatedAt: !isNaN(
            Date.parse(moment.utc(eve.createdAt).format(momentDateTimeFormat))
        )
            ? new Date(Date.parse(moment(eve.createdAt).format(momentDateTimeFormat)))
            : null,
    }))
    return mappedEvents;
}

export const mapNotificationsGridData = (notifications: Array<INotification>): Array<INotificationGridModel> => {
    let mappedNotifications = notifications.map((notif) => ({
        ...notif,
        formattedCreatedAt: !isNaN(
            Date.parse(moment.utc(notif.createdAt).format(momentDateTimeFormat))
        )
            ? new Date(Date.parse(moment(notif.createdAt).format(momentDateTimeFormat)))
            : null,
        fromNow: moment(notif.createdAt).local().fromNow()
    }))
    return mappedNotifications;
}
