import axios from "axios";

//This is the WebApp backend API.
const cameraUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/cameras`;

export const getCamera = async (systemSourceId: string, sourceId: string) => {
  const response = await axios.get(
    `${cameraUrl}?systemSourceId=${systemSourceId}&sourceId=${sourceId}`
  );
  return response.data;
};

export const getCameraToken = async () => {
  const response = await axios.get(`${cameraUrl}/token`);
  return response.data;
};

export const getCameraFromDivas = async (cameraId: string, token: string) => {
  try {
    const url = `${fdot.process.env.DIVAS_API_URL}/SecureTokenUri/GetSecureTokenUriByCameraId`;
    const requestData = {
      cameraId,
      token,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    const response = await axios.post(url, requestData, { headers });
    return response.data;
  } catch (error: any) {
    console.log(
      `Error getting divas secure uri camera divas cloud ${error.message}`,
      error
    );
    return null;
  }
};
