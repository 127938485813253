import React, { CSSProperties } from 'react';

const Footer = () => {

    const footerStyles: CSSProperties = {
        backgroundColor: "#EDF1F5"
    }

    const linksFontColor: CSSProperties = {
        color: "darkblue"
    }

    return (
        <div className="container-fluid">
            <div className="row justify-content-md-center">
                <nav className="navbar fixed-bottom navbar-light" style={footerStyles}>
                    <div className="col text-center">
                        SunEx Management
                        <br />
                        Report technical problems to the&nbsp;
                        <a href={`mailto:${fdot.process.env.SERVICE_DESK_EMAIL}?FDOT Template" title="Use this link to E-Mail concerns, problems, and/or comments`} style={linksFontColor} >
                            Service Desk
                        </a>
                        &nbsp;at {fdot.process.env.SERVICE_DESK_PHONE}
                        <br />
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            style={linksFontColor}
                            title="Web Policies and Notices (Opens new browser window)"
                            href={fdot.process.env.SERVICE_DESK_POLICIES}>
                            Web Policies and Notices
                        </a>
                        &nbsp; - &nbsp;
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            style={linksFontColor}
                            href={fdot.process.env.SERVICE_DESK_ACCESSIBILITY}
                            title="Accessibility Statement  (Opens new browser window)">
                            Accessibility Statement
                        </a>
                    </div>
                </nav >
            </div>
        </div >
    );
}

export default Footer;