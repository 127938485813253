// import { State } from "@progress/kendo-data-query";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { INotification } from "../../../../shared/models/INotification";
import { IEvent } from "../../../../shared/models/IEvent";

interface ISort {
  field: string;
  dir: "asc" | "desc" | "none";
}
interface ISortSkipState {
  sort: ISort;
  take: number;
  eventskip: number;
  eventcurrentPageIndex: number;

  notificationskip: number;
  notificationcurrentPageIndex: number;
}

export interface ISearchCriteria {
  searchCriteriaState: ISortSkipState;
  selectedEventType: string | undefined;
  district: string | undefined;
}

// slice & selectors omitted
type SliceState = {
  criteria: ISearchCriteria;
  eventLoading: boolean;
  notificationLoading: boolean;
  currentEvents: IEvent[];
  notificationsEvents: INotification[];
  totalEventsCount: number;
  totalNotificationsCount: number;
};

const baseUrl = fdot.process.env.BACKEND_SERVER_HOST;

export const fetchEvents = createAsyncThunk<any, ISearchCriteria>(
  "events/fetch",
  async (criteria, { rejectWithValue }) => {
    const response = await fetch(
      `${baseUrl}/api/notificationEvents/${criteria.district?.toLowerCase()}?take=${
        criteria.searchCriteriaState.take
      }&skip=${criteria.searchCriteriaState.eventskip}`,
      {
        method: "GET",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
      }
    );

    const data = await response.json();
    if (response.status < 200 || response.status >= 300) {
      return rejectWithValue(data);
    }
    return data;
  }
);

export const fetchNotifications = createAsyncThunk<any, ISearchCriteria>(
  "notifications/fetch",
  async (criteria, { rejectWithValue }) => {
    const response = await fetch(
      `${baseUrl}/api/notifications/${criteria.district?.toLowerCase()}?take=${
        criteria.searchCriteriaState.take
      }&skip=${criteria.searchCriteriaState.notificationskip}`,
      {
        method: "GET",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
      }
    );

    const data = await response.json();
    if (response.status < 200 || response.status >= 300) {
      return rejectWithValue(data);
    }
    return data;
  }
);
const searchSlice = createSlice({
  name: "search",
  initialState: {
    criteria: {
      searchCriteriaState: {
        sort: { field: "serviceRequestId", dir: "asc" },
        take: 5,
        eventskip: 0,
        eventcurrentPageIndex: 0,
        notificationskip: 0,
        notificationcurrentPageIndex: 0,
      },
      searchText: "",
      selectedEventType: "",
      district: "D1",
    },
    eventLoading: false,
    notificationLoading: false,
    currentEvents: [],
    notificationsEvents: [],
    totalEventsCount: 0,
    totalNotificationsCount: 0,
    serviceRequest: null,
  } as SliceState,
  reducers: {},
  extraReducers: (builder) => {
    // When our request is pending:
    // - store the 'pending' state as the status for the corresponding pokemon name
    builder.addCase(fetchEvents.pending, (state, action) => {
      state.criteria = action.meta.arg;
      state.eventLoading = true;
      state.currentEvents = [];
      state.totalEventsCount = 0;
    });
    // When our request is fulfilled:
    // - store the 'fulfilled' state as the status for the corresponding pokemon name
    // - and store the received payload as the data for the corresponding pokemon name
    builder.addCase(fetchEvents.fulfilled, (state, action) => {
      // state.criteria = action.meta.arg;
      state.eventLoading = false;
      state.currentEvents = action.payload.results;
      state.totalEventsCount = action.payload.totalCount;
    });
    // When our request is rejected:
    // - store the 'rejected' state as the status for the corresponding pokemon name
    builder.addCase(fetchEvents.rejected, (state, action) => {
      // state.criteria = action.meta.arg;
      state.eventLoading = false;
      state.currentEvents = [];
      state.totalEventsCount = 0;
    });

    // - store the 'pending' state as the status for the corresponding pokemon name
    builder.addCase(fetchNotifications.pending, (state, action) => {
      state.criteria = action.meta.arg;
      state.notificationLoading = true;
      state.notificationsEvents = [];
      state.totalNotificationsCount = 0;
    });
    // When our request is fulfilled:
    // - store the 'fulfilled' state as the status for the corresponding pokemon name
    // - and store the received payload as the data for the corresponding pokemon name
    builder.addCase(fetchNotifications.fulfilled, (state, action) => {
      // state.criteria = action.meta.arg;
      state.notificationLoading = false;
      state.notificationsEvents = action.payload.results;
      state.totalNotificationsCount = action.payload.totalCount;
    });
    // When our request is rejected:
    // - store the 'rejected' state as the status for the corresponding pokemon name
    builder.addCase(fetchNotifications.rejected, (state, action) => {
      // state.criteria = action.meta.arg;
      state.notificationLoading = false;
      state.notificationsEvents = [];
      state.totalNotificationsCount = 0;
    });
  },
});

export default searchSlice.reducer;
