import React from "react";
import { GoogleMap, useLoadScript, Marker, TrafficLayer } from "@react-google-maps/api";
import { style } from "../../mapStyles";
import { ggApi } from "../../config/config.json";

interface IProps {
    geoLocation: any,
    zoom: any,
    onClick?: any,
    onDragEnd?: any,
    width: any,
    height: any
}

const TrafficMap: React.FC<IProps> = (props: IProps) => {

    const options = {
        styles: style,
        disableDefaultUI: true,
    };

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: ggApi, // ,
        // ...otherOptions
    });

    const renderMap = () => {
        // wrapping to a function is useful in case you want to access `window.google`
        // to eg. setup options or create latLng object, it won't be available otherwise
        // feel free to render directly if you don't need that
        return (
            <GoogleMap mapContainerStyle={{ width: props.width, height: props.height }} center={props.geoLocation} zoom={props.zoom} options={options} onClick={props.onClick}>
                <TrafficLayer />
                <Marker position={props.geoLocation} draggable={true} onDragEnd={props.onDragEnd} />
            </GoogleMap>
        );
    };

    if (loadError) {
        return <div>Map cannot be loaded right now, sorry.</div>;
    }

    return isLoaded ? renderMap() : <p>Loading</p>;

}

export default TrafficMap;