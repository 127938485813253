import { useEffect, useRef, useState } from "react"
import { getEventsByEventId, getLatestEvents } from "../../apis/EventApi"
import { CustomCard } from "../common/CustomCard"
import {
    Grid,
    GridColumn,
    GridFilterCellProps
} from "@progress/kendo-react-grid";
import { FilterDescriptor, process } from "@progress/kendo-data-query";
import {
    getGridDataState,
    IGridDataSortOrder,
} from "../common/grid/gridDataState";
import { IEvent } from "../../../../shared/models/IEvent";
import { customCellRender, customHeaderRender } from "../common/grid/customCellRender";
import "../../App.css";
import "@progress/kendo-theme-bootstrap/dist/all.css";
import { appInsights } from "../../utils/Initializer";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useNavigate } from 'react-router-dom';
import { IEventGridModel } from "../../models/Events/IEventGridModel";
import { mapEventsGridData } from "../common/gridDataHelper";
import { LoadingImage } from "../common/LoadingImage";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Button } from "@progress/kendo-react-buttons";
import {
    filterClearIcon
} from "@progress/kendo-svg-icons";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/RootReducer";

const EventsList: React.FC = () => {
    let navigate = useNavigate();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [eventsList, setEventsList] = useState<
        Array<IEventGridModel>
    >([]);
    const [searchTerms, setSearchTerms] = useState("");

    const { user } = useSelector(
        (state: RootState) => state.userReducer
    );

    //useState vs useRef : we don't want this variable to cause re-render so useref is used.
    const isFirstRun = useRef(true);

    const [dataState, setDataState] = useState(
        getGridDataState("formattedCreatedAt", IGridDataSortOrder.desc, true, 10)
    );

    const getData = async () => {
        setLoading(true);
        setError(false);

        let eventsResponse = await getLatestEvents();
        let mappedEvents = mapEventsGridData(eventsResponse.events)
        setEventsList(mappedEvents);

        setLoading(false);
    }

    const getDataByEventId = async () => {
        setLoading(true);
        setError(false);

        let eventsResponse = await getEventsByEventId(searchTerms);
        let mappedEvents = mapEventsGridData(eventsResponse.events);

        setEventsList(mappedEvents);
        setLoading(false);
    }

    useEffect(() => {
        try {
            getData();
        }
        catch (error: any) {
            setError(true);
            setLoading(false);
            appInsights.trackException({
                error,
                id: "Fetching all Events",
                severityLevel: SeverityLevel.Critical,
                properties: {
                    serverResponse: `${error.response}`,
                },
            });
        }
    }, [])

    useEffect(() => {
        if (!isFirstRun.current) {
            const delayDebounceFn = setTimeout(
                () => {
                    if (searchTerms) {
                        console.log("Searching by Event Ids");
                        getDataByEventId()
                    } else {
                        console.log("Searching by last 24 hours");
                        getData();
                    }
                }, 2000
            );

            return () => clearTimeout(delayDebounceFn);
        }
    }, [searchTerms]);

    const actionsCell = (event: any) => {
        const item: IEvent = event.dataItem;
        return (
            <td>
                <>
                    <button
                        id={`view_${item._id}`}
                        title="Notify"
                        className="btn btn-primary notifyBtn"
                        onClick={() => {
                            sessionStorage.removeItem("PreviewNotification");
                            navigate(`/events/${item.eventId}`);
                        }}
                    >
                        Notify
                    </button>
                </>
            </td>
        );
    };

    const [dateFilterValue, setDateFilterValue] = useState<Date | null>(null);
    const CreatedAtFilterCell: any = (props: GridFilterCellProps) => (
        <div className="k-filtercell">
            <DatePicker
                format={"MM/dd/yyyy"}
                value={dateFilterValue}
                placeholder="mm/dd/yyyy"
                onChange={e => {
                    if (e.value) {
                        const today = e.value;
                        today.setHours(0, 0, 0, 0)
                        const tomorrow = new Date(today);
                        tomorrow.setDate(today.getDate() + 1)
                        setDateFilterValue(new Date(today))
                        if (!dataState.filter) {
                            dataState.filter = {
                                logic: "and",
                                filters: [
                                    {
                                        field: "formattedCreatedAt",
                                        operator: "gte",
                                        value: today
                                    },
                                    {
                                        field: "formattedCreatedAt",
                                        operator: "lte",
                                        value: tomorrow
                                    },
                                ]
                            }
                        } else {
                            const noDates = dataState.filter.filters.filter(item => (item as FilterDescriptor).field !== "formattedCreatedAt")
                            dataState.filter.filters = noDates;
                            dataState.filter.filters.push(
                                {
                                    field: "formattedCreatedAt",
                                    operator: "gte",
                                    value: today
                                }
                            )
                            dataState.filter.filters.push(
                                {
                                    field: "formattedCreatedAt",
                                    operator: "lte",
                                    value: tomorrow
                                },
                            )
                        }
                        setDataState(dataState)
                    }
                }}
            />
            <Button
                title="Clear"
                className="btn"
                svgIcon={filterClearIcon}
                disabled={dateFilterValue === null}
                onClick={() => {
                    if (dataState.filter) {
                        const noDates = dataState.filter.filters.filter(item => (item as FilterDescriptor).field !== "formattedCreatedAt")
                        dataState.filter.filters = noDates;
                        setDateFilterValue(null);
                        setDataState(dataState)
                    }
                }}
            >
            </Button>
        </div>
    );

    return (<>
        <CustomCard headerText="Events List">
            <div className="d-xl-block">
                {loading ? (<LoadingImage></LoadingImage>) : (
                    <>
                        <div className="card card-body bg-light">
                            <div className="row">
                                <div className="col-3">
                                    <input
                                        name="searchTermText"
                                        id="searchTermText"
                                        type="text"
                                        value={searchTerms}
                                        title="Search Old Events"
                                        className="form-control"
                                        placeholder="Events older than 24 hours (Full Event ID)"
                                        onChange={(e) => {
                                            const search = e.target.value.replace(/\D/g, "");
                                            isFirstRun.current = false;
                                            setSearchTerms(search);
                                        }}
                                    />
                                </div>
                                <div className="col text-right">
                                    {user?.isAdmin && <button
                                        className="btn btn-primary py-3"
                                        title="Create Custom Notification"
                                        id="addCustomNotificationBtn"
                                        onClick={() => navigate("/custom")}
                                    >
                                        Add Custom Notification
                                    </button>}
                                </div>
                            </div>
                        </div>
                        <Grid
                            sortable
                            filterable
                            resizable
                            reorderable
                            pageable={{ buttonCount: 4, pageSizes: [5, 10, 15, 20] }}
                            data={process(eventsList, dataState)}
                            {...dataState}
                            onDataStateChange={(e) => {
                                setDataState(e.dataState);
                            }}
                            cellRender={customCellRender}
                            headerCellRender={customHeaderRender}
                        >
                            <GridColumn
                                field="eventId"
                                title="Event Id"
                                headerClassName="text-wrap"
                            />
                            <GridColumn
                                field="formattedCreatedAt"
                                title="Created Date"
                                format="{0:M/d/yyyy hh:mm:ss aa}"
                                filter="date"
                                filterTitle="Filter Date"
                                headerClassName="text-wrap"
                                width="220rem"
                                filterCell={CreatedAtFilterCell}
                            />
                            <GridColumn field="type" title="Type" />
                            <GridColumn field="districtCode" title="District" />
                            <GridColumn
                                field="roadway"
                                title="Roadway"
                                headerClassName="text-wrap"
                            />
                            <GridColumn
                                field="direction"
                                title="Direction"
                                headerClassName="text-wrap"
                            />
                            <GridColumn
                                field="proximity"
                                title="Proximity"
                                headerClassName="text-wrap"
                            />
                            <GridColumn
                                field="crossStreet"
                                title="Location"
                                headerClassName="text-wrap"
                            />
                            <GridColumn
                                field="county"
                                title="County"
                                headerClassName="text-wrap"
                            />
                            <GridColumn
                                field="mileMarker"
                                title="Mile Marker"

                                headerClassName="text-wrap"
                            />
                            <GridColumn
                                width="100rem"
                                filterable={false}
                                sortable={false}
                                title="Actions"
                                cell={actionsCell}
                            />
                        </Grid>
                    </>
                )}
            </div>
        </CustomCard>
    </>)
}

export default EventsList