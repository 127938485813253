import React, { useState, useEffect, CSSProperties } from "react";
import { INotification } from "../../../../shared/models/INotification";
import ReactPlayer from "react-player";
import TrafficMap from "../common/trafficMap";
import moment from "moment";
import TSMO from "../../assets/logos/tsm&o-white.png";
import { createNotification } from "../../apis/NotificationApi";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { LoadingImage } from "../common/LoadingImage";


const NotificationPreview: React.FC = () => {
    const logoContainerStyle: CSSProperties = {
        borderRadius: "5px 5px 0px 0px",
        backgroundColor: "#006BD6",
        maxWidth: "550px",
        margin: "0px"
    }
    const previewContainerStyle: CSSProperties = {
        margin: "0px",
        padding: "7px 0px",
        borderRadius: 5,
        backgroundColor: "whitesmoke",
        overflow: "hidden",
        maxWidth: "550px"
    }
    const previewBodyStyle: CSSProperties = {
        padding: "15px",
        borderRadius: 5,
        backgroundColor: "#B0C4DE",
        overflow: "hidden",
        maxWidth: "550px"
    }
    const paragraphStyle: CSSProperties = {
        backgroundColor: "#fff",
        padding: "10px",
        margin: "10px 0px"
    }

    let navigate = useNavigate();

    const [notification, setNotification] = useState<INotification | null>(null);
    const [submitting, setSubmitting] = useState(false);


    useEffect(() => {
        try {
            const previewData = sessionStorage.getItem("PreviewNotification");
            const notificationObject = JSON.parse(previewData!) as INotification;
            setNotification(notificationObject!);
        } catch (error: any) {
            console.log(error)
        }
    }, [])

    const submitNotification = () => {
        setSubmitting(true)

        if (notification?.isEmergency) {
            notification.notificationReach.push("em")
        }

        const today = new Date();
        notification!.createdAt = today;
        notification!.createdDate = today;

        createNotification(notification!)
            .then(res => {
                sessionStorage.removeItem("PreviewNotification");
                toast.success("Notification Created.");
                navigate(`/notifications`);
            })
            .catch(err => {
                toast.error("ERROR: while submitting Notification!", { style: { backgroundColor: "white", color: "red" } });
                console.log(err)
                setSubmitting(false)
            });
    }

    const previousView = () => {
        navigate(`/events/${notification!.eventId}`);
    }

    return (
        <>
            <div className="row justify-content-center mt-4 mb-0">
                <div className="col-12" style={logoContainerStyle}>
                    <img style={{ width: "180px" }} src={TSMO} alt="TSMO Logo" />
                </div >
            </div >
            <div className="row justify-content-center">
                <div className="col-12" style={previewContainerStyle}>
                    <h3>{notification?.status}</h3>
                    <div className="row justify-content-center m-4" >
                        <div className="col-12" style={previewBodyStyle}>
                            <h4>Title: {notification?.title}</h4>
                            <h6>Subject: {notification?.narrativeActions}</h6>
                            {notification && notification.hlsUri && (
                                <ReactPlayer
                                    url={notification!.hlsUri}
                                    controls
                                    playing
                                    width="100%"
                                />
                            )}
                            <p style={paragraphStyle}>
                                Time of Incident IN UTC: {moment.utc(notification?.confirmedDate).format("MM/DD/YYYY")} {moment.utc(notification?.confirmedTime!, "HH:mm:ss.SSSSSSZ").format("HH:mm:ss")}<br />
                                Incident Type: {notification?.incidentType}<br />
                                District: {notification?.district}<br />
                                Location: {notification?.location}<br />
                                Mile Marker: {notification?.mileMarker}<br />
                                Facility Status: {notification?.facilityStatus}<br />
                                Duration of Closure: {notification?.durationOfClosure}<br />
                                Fatalities: {notification?.fatalities}<br />
                                Injuries: {notification?.injuries}<br />
                                Number of Vehicles: {notification?.numberOfVehicles}<br />
                                Type of Vehicles: {notification?.typeOfVehicles}<br />
                                Narrative and Response Action(s): {notification?.narrativeActions}<br />
                                SunGuide Event: {notification?.eventId}<br />
                            </p>
                            <TrafficMap zoom={12} width="100%" height="300px" geoLocation={{ lat: notification?.latitude, lng: notification?.longitude }}></TrafficMap>
                            {submitting ? (<LoadingImage></LoadingImage>) : (<div className="row justify-content-left mt-3" >
                                <div className="col-4"><button className="btn btn-light btn-lg" onClick={previousView}>PREVIOUS</button></div>
                                <div className="col-4"><button className="btn btn-primary btn-lg" onClick={submitNotification} disabled={submitting}>SUBMIT</button></div>
                            </div>)}
                        </div>
                    </div >
                </div>
            </div>
        </>
    );
}

export default NotificationPreview;