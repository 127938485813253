import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSun,
  faMoon,
  faPlus,
  faChevronDown,
  faChevronUp,
  faCloudDownloadAlt,
  faUpload,
  faSave,
  faCheck,
  faTimes,
  faShoppingCart,
  faMoneyBillWave,
  faFileInvoiceDollar,
  faAngleDoubleRight,
  faPrint,
  faFlag,
  faPlusCircle,
  faMinusCircle,
  faTrashAlt,
  faCheckCircle,
  faTimesCircle,
  faEdit,
  faInfoCircle,
  faArrowCircleRight,
  faFilePdf,
  faDownload,
  faPaperPlane,
  faExclamationTriangle,
  faSearch,
  faComment,
  faUndo,
  faFileExcel,
  faArrowAltCircleLeft,
  faHistory,
  faPen,
  faEye,
  faArrowCircleUp,
  faStream,
  faTrash,
  faExclamationCircle,
  faBookOpen,
  faCertificate,
  faCloudUploadAlt,
  faLocationArrow,
  faRecycle,
  faQuestionCircle,
  faChartLine,
  faPenSquare,
  faCheckDouble,
  faArchive,
  faBan,
} from "@fortawesome/free-solid-svg-icons";
// If you need any regular icons use something like this to import:
// import { faSquare } from '@fortawesome/free-regular-svg-icons';

export const initializeIcons = () => {
  library.add(faExclamationTriangle);
  library.add(faExclamationTriangle);
  library.add(faFileExcel);
  library.add(faSun);
  library.add(faMoon);
  library.add(faMinusCircle);
  library.add(faPlusCircle);
  library.add(faPlus);
  library.add(faTrash);
  library.add(faEdit);
  library.add(faChevronDown);
  library.add(faChevronUp);
  library.add(faCloudDownloadAlt);
  library.add(faDownload);
  library.add(faCloudUploadAlt);
  library.add(faUpload);
  library.add(faSave);
  library.add(faBan);
  library.add(faQuestionCircle);
  library.add(faSearch);
  library.add(faCheck);
  library.add(faTimes);
  library.add(faShoppingCart);
  library.add(faMoneyBillWave);
  library.add(faFileInvoiceDollar);
  library.add(faUndo);
  library.add(faFilePdf);
  library.add(faAngleDoubleRight);
  library.add(faPrint);
  library.add(faFlag);
  library.add(faTrashAlt);
  library.add(faCheckCircle);
  library.add(faTimesCircle);
  library.add(faInfoCircle);
  library.add(faArrowCircleRight);
  library.add(faPaperPlane);
  library.add(faComment);
  library.add(faArrowAltCircleLeft);
  library.add(faHistory);
  library.add(faPen);
  library.add(faEye);
  library.add(faArrowCircleUp);
  library.add(faStream);
  library.add(faExclamationCircle);
  library.add(faBookOpen);
  library.add(faCertificate);
  library.add(faLocationArrow);
  library.add(faRecycle);
  library.add(faChartLine);
  library.add(faPenSquare);
  library.add(faCheckDouble);
  library.add(faArchive);
};
