import React from "react";
import { CustomHeaderCell } from "./customHeaderCell";
import { FocusableCell } from "./focusableCell";
import { FocusableBorderedCell } from "./focusableBorderedCell";

export const customCellRender = (e: any) => {
  return <FocusableCell tdProps={e.props} />;
};

export const customBorderedCellRender = (e: any) => {
  return <FocusableBorderedCell tdProps={e.props} />;
};

export const customHeaderRender = (e: any) => {
  return <CustomHeaderCell thProps={e.props} />;
};
