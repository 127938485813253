import { State } from "@progress/kendo-data-query";

export enum IGridDataSortOrder {
  asc = "asc",
  desc = "desc",
}

//if pagination is true, pageSize must be passed
export const getGridDataState = (
  field: string,
  dir: IGridDataSortOrder,
  pagination: boolean,
  pageSize?: number
): State => {
  return pagination
    ? { sort: [{ field, dir }], take: pageSize, skip: 0 }
    : { sort: [{ field, dir }] };
};
