import React from "react";

interface IProps {
    errors: any;
    isValid: boolean;
}

interface KeyedItem {
    key: string;
    value: any;
}

const getAllErrors = (errors: any): Array<KeyedItem> => {
    let errorsArray: Array<KeyedItem> = [];
    if (errors === undefined || errors === null) {
        return errorsArray;
    }
    const keys = Object.keys(errors);
    for (var key of keys) {
        const value = errors[key];
        if (typeof value === "string") {
            errorsArray.push({ key, value });
        } else if (typeof value === "object" || value !== null) {
            errorsArray = errorsArray.concat(getAllErrors(value));
        }
    }

    return errorsArray;
};

export const FormValidationSummary = (props: IProps) => {
    let errorsArray: Array<KeyedItem> = [];
    if (props.errors !== undefined || props.errors !== null) {
        errorsArray = getAllErrors(props.errors);
    }

    if (errorsArray.length === 0 || props.isValid) {
        return null;
    }

    if (errorsArray.length === 0) {
        return null;
    }

    return (
        <div>
            <ul>
                {errorsArray.map((i, index) => (
                    <li key={index} className="text-danger">
                        <strong>ERROR:</strong> {i.value} (<a href={`#${i.key}`}>Fix</a>)
                    </li>
                ))}
            </ul>
        </div>
    );
};
