import React, { CSSProperties, useEffect, useState } from "react"
import { getLatestNotifications } from "../../apis/NotificationApi"
import { CustomCard } from "../common/CustomCard"
import {
    Grid,
    GridColumn,
    GridRow,
    GridRowProps,
} from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import {
    getGridDataState,
    IGridDataSortOrder,
} from "../common/grid/gridDataState";
import { INotification } from "../../../../shared/models/INotification"
import { customBorderedCellRender, customHeaderRender } from "../common/grid/customCellRender";
import "../../App.css";
import "@progress/kendo-theme-bootstrap/dist/all.css";
import { appInsights } from "../../utils/Initializer";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useNavigate } from 'react-router-dom';
import { INotificationGridModel } from "../../models/Events/INotificationGridModel";
import { mapNotificationsGridData } from "../common/gridDataHelper";
import { LoadingImage } from "../common/LoadingImage";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/RootReducer";

const NotificationsList: React.FC = () => {
    const customButtonStyle: CSSProperties = {
        margin: "10px 0px 20px 0px",
    }
    const customActinCellStyle: CSSProperties = {
        borderBottom: "0.5px solid #dee2e6"
    }

    let navigate = useNavigate();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [notificationsList, setNotificationsList] = useState<
        Array<INotificationGridModel>
    >([]);

    const [dataState, setDataState] = useState(
        getGridDataState("formattedCreatedAt", IGridDataSortOrder.desc, true, 10)
    );

    const { user } = useSelector(
        (state: RootState) => state.userReducer
    );

    const getData = async () => {
        setLoading(true);
        setError(false);

        let notificationsResponse = await getLatestNotifications();
        let mappedNotifications = mapNotificationsGridData(notificationsResponse.notifications)

        setNotificationsList(mappedNotifications);

        setLoading(false);

    }

    useEffect(() => {
        try {

            getData();
        }
        catch (error: any) {
            setError(true);
            appInsights.trackException({
                error,
                id: "Fetching all Reviews",
                severityLevel: SeverityLevel.Critical,
                properties: {
                    serverResponse: `${error.response}`,
                },
            });
        }
    }, [])

    const actionsCell = (event: any) => {
        const item: INotification = event.dataItem;

        return (
            <td style={customActinCellStyle}>
                <>
                    {item.eventId && (
                        <button
                            id={`view_${item._id}`}
                            title="Notify"
                            className="btn btn-primary"
                            onClick={() => navigate(`/events/${item.eventId}`)}
                        >
                            Notify
                        </button>
                    )}
                </>
            </td>
        );
    };

    const customRowRender = (tr: any, props: any) => {
        if (props.dataItem.status === "New") {
            tr.props.style.backgroundColor = '#fd5c63';
        } else if (props.dataItem.status === "Update") {
            tr.props.style.backgroundColor = "yellow";
        } else {
            tr.props.style.backgroundColor = '#90ee90';
        }
        return React.cloneElement(tr, { ...tr.props }, tr.props.children)
    }

    return (<>
        <CustomCard headerText="Notifications List">
            <div className="d-xl-block">
                <div className="row">
                    <div className="col-12 text-end">
                        {user?.isAdmin && <button
                            className="btn btn-primary py-3"
                            title="Create Custom Notification"
                            id="addCustomNotificationBtn"
                            style={customButtonStyle}
                            onClick={() => navigate("/custom")}
                        >
                            Add Custom Notification
                        </button>}
                    </div>
                </div>
                {loading ? (<LoadingImage></LoadingImage>) : (
                    <Grid
                        sortable
                        filterable
                        resizable
                        reorderable
                        pageable={{ buttonCount: 4, pageSizes: [5, 10, 15, "All"] }}
                        data={process(notificationsList, dataState)}
                        {...dataState}
                        onDataStateChange={(e) => {
                            setDataState(e.dataState);
                        }}
                        cellRender={customBorderedCellRender}
                        headerCellRender={customHeaderRender}
                        rowRender={customRowRender}
                    >
                        <GridColumn
                            field="eventId"
                            title="Event ID"
                            headerClassName="text-wrap"
                        />
                        {/* <GridColumn
                        field="formattedCreatedAt"
                        title="Created Date"
                        format="{0:M/d/yyyy hh:mm:ss aa}"
                        headerClassName="text-wrap"
                    /> */}
                        <GridColumn
                            field="fromNow"
                            title="Sent"
                            headerClassName="text-wrap"
                        />
                        <GridColumn field="status" title="Status" headerClassName="text-wrap" />
                        <GridColumn field="title" title="Title" headerClassName="text-wrap" />
                        <GridColumn
                            field="facilityStatus"
                            title="Facility Status"
                            headerClassName="text-wrap"
                        />
                        <GridColumn
                            filterable={false}
                            sortable={false}
                            title="Actions"
                            cell={actionsCell}
                        />
                    </Grid>
                )}
            </div>
        </CustomCard>
    </>)
}

export default NotificationsList