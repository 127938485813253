import React, { CSSProperties } from 'react';
import Switch from "react-switch";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { changeTheme } from "../../redux/slices/ProfileSlice";
import { RootState } from '../../redux/RootReducer';
import { Theme } from '../../../../shared/models/enums/Theme';


export const ThemeSelector: React.FC = () => {
    const { theme } = useSelector((state: RootState) => state.profileReducer);
    const { user } = useSelector((state: RootState) => state.userReducer);
    const dispatch = useDispatch();

    const updateTheme = () => {
        if (!user) {
            return;
        }
        dispatch(changeTheme(theme === Theme.Dark ? Theme.Standard : Theme.Dark, user));
    }

    const style: CSSProperties = {
        marginTop: '7px',
        marginLeft: '7px'
    }

    return (
        <label htmlFor="icon-switch" title="Toggle between Standard to Dark mode">
            <Switch
                checked={theme === Theme.Dark}
                aria-checked={theme === Theme.Dark}
                onChange={updateTheme}
                uncheckedIcon={
                    <FontAwesomeIcon icon="sun" style={style} />
                }
                checkedIcon={
                    <FontAwesomeIcon icon="moon" style={style} />
                }
                className="react-switch"
                id="icon-switch"
            />
        </label>
    );
}