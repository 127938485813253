import * as userApi from '../../apis/UserApi';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProfile } from '../../../../shared/models/IProfile';
import { Theme } from '../../../../shared/models/enums/Theme';
import { IUser } from '../../../../shared/models/IUser';

type SliceState = {
    theme: Theme;
}

const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        theme: Theme.Standard
    } as SliceState,
    reducers: {
        updateTheme: (state: SliceState, action: PayloadAction<Theme>) => {
            state.theme = action.payload;
            return state;
        }
    }
});

export const { updateTheme } = profileSlice.actions;
export default profileSlice.reducer;

export const changeTheme = (theme: Theme, user: IUser) => async (dispatch: any) => {
    const { azureAdOid, external, srsId } = user;
    userApi.updateProfile({ theme, azureAdOid, externalUser: external, srsId })
        .then(
            (updatedProfile: IProfile) => {
                dispatch(updateTheme(updatedProfile.theme));
            })
        .catch((error: any) => {
            console.log(error);
        });
}

export const fetchUserProfile = (user: IUser) => async (dispatch: any) => {
    const { srsId, azureAdOid, external } = user;
    userApi.fetchOrCreateProfile({srsId, azureAdOid, externalUser: external, theme: Theme.Standard})
        .then(
            (profile: IProfile) => {
                dispatch(updateTheme(profile.theme));
            })
        .catch((error: any) => {
            console.log(error);
        });
} 