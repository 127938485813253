import React, { useState, useEffect, CSSProperties } from "react";
import { useParams } from "react-router";
import {
    Formik,
    Form,
    Field,
    ErrorMessage,
    FormikHelpers,
    FieldArray,
    FieldArrayRenderProps,
} from "formik"
import { INotification } from "../../../../shared/models/INotification";
import { CustomCard } from "../common/CustomCard";
import { IEvent } from "../../../../shared/models/IEvent";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { appInsights } from "../../utils/Initializer";
import { toast } from "react-toastify";
import { getEventByNumber } from "../../apis/EventApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isNullOrUndefinedOrWhiteSpace, utcToISODateString } from "../../utils/stringUtils";
import { renderErrorMessage } from "../common/formik/ValidationHelpers";
import ReactPlayer from 'react-player';
import { useNavigate } from 'react-router-dom';
import { getCamera, getCameraFromDivas } from "../../apis/CameraApi";
import { FormValidationSummary } from "../common/validationSummary";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/RootReducer";
import { LoadingImage } from "../common/LoadingImage";
import ErrorPage from "../common/errorPage";


const AddNotification: React.FC = () => {
    const errorMessageStyle: CSSProperties = {
        color: "#de3f4e"
    }

    let navigate = useNavigate();

    const { user } = useSelector(
        (state: RootState) => state.userReducer
    );

    const { id } = useParams();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);


    const setInitialValues = (event: IEvent | null): INotification => {
        const today = new Date();
        if (event !== null) {
            return {
                isCustom: false,
                signature: "",
                district: event.districtCode,
                isEmergency: false,
                title: `Event ${event.eventId} ${event.type} ${event.proximity} ${event.roadway} ${event.direction} in ${event.county} County.`,
                includeCctv: event.cctvId !== undefined && event.cctvId !== "",
                cctvId: event.cctvId,
                eventId: event.eventId.toString(),
                // location: `${event.roadway} ${event.direction} ${event.proximity} Mile Marker ${event.mileMarker} in ${event.county}`,
                location: `${event.county} on ${event.roadway} ${event.direction}, ${event.proximity} Mile Marker ${event.mileMarker}`,
                hlsUri: "",
                incidentType: event.type,
                confirmedDate: utcToISODateString(event.confirmedDate),
                confirmedTime: moment.utc(event?.confirmedTime!, "HH:mm:ss.SSSSSSZ").format("HH:mm:ss"),
                durationOfClosure: event.durationOfClosure.toString(),
                facilityStatus: event.facilityStatus ?? "",
                fatalities: "Unknown",
                injuries: "Unknown",
                mileMarker: event.mileMarker.toString(),
                latitude: event.latitude,
                longitude: event.longitude,
                numberOfVehicles: "Unknown",
                typeOfVehicles: "Unknown",
                status: "",
                createdAt: today,
                subject: "",
                narrativeActions: "",
                sourceId: event.cctvId,
                systemSourceId: event.districtCode,
                notificationReach: [user?.district!],
                notificationType: "Executive Notification",
                archived: false
            };
        } else {
            return {
                isCustom: false,
                signature: "",
                district: "",
                isEmergency: false,
                title: "",
                includeCctv: false,
                cctvId: "",
                eventId: "",
                location: "",
                hlsUri: "",
                incidentType: "",
                confirmedDate: "",
                confirmedTime: "",
                durationOfClosure: "",
                facilityStatus: "",
                fatalities: "",
                injuries: "",
                mileMarker: "",
                latitude: 0,
                longitude: 0,
                numberOfVehicles: "",
                typeOfVehicles: "",
                status: "",
                createdAt: today,
                subject: "",
                narrativeActions: "",
                sourceId: "",
                systemSourceId: "",
                notificationReach: [user?.district!],
                notificationType: "Executive Notification",
                archived: false
            };
        }
    };

    const [initialNotificationValues, setInitialNotificationValues] = useState<INotification>(
        setInitialValues(null)
    );

    const deepCopy = (notification: INotification): INotification => {
        return {
            ...notification,
            isCustom: notification.isCustom,
            signature: notification.signature,
            district: notification.district,
            isEmergency: notification.isEmergency,
            title: notification.title,
            includeCctv: notification.includeCctv,
            cctvId: notification.cctvId,
            eventId: notification.eventId,
            location: notification.location,
            hlsUri: notification.hlsUri,
            incidentType: notification.incidentType,
            confirmedDate: notification.confirmedDate,
            confirmedTime: notification.confirmedTime,
            durationOfClosure: notification.durationOfClosure,
            facilityStatus: notification.facilityStatus,
            fatalities: notification.fatalities,
            injuries: notification.injuries,
            mileMarker: notification.mileMarker,
            latitude: notification.latitude,
            longitude: notification.longitude,
            numberOfVehicles: notification.numberOfVehicles,
            typeOfVehicles: notification.typeOfVehicles,
            status: notification.status,
            createdAt: notification.createdAt,
            sourceId: notification.sourceId,
            systemSourceId: notification.systemSourceId,
            notificationReach: notification.notificationReach
        }
    }

    const changeReachHelper = (e: React.ChangeEvent<HTMLInputElement>, notification: INotification, arrayHelpers: FieldArrayRenderProps) => {
        if (e.target.checked) arrayHelpers.push(e.target.value);
        else {
            const idx = notification.notificationReach.indexOf(e.target.value);
            arrayHelpers.remove(idx);
        }
    }

    useEffect(() => {
        const notificationFromPreview = sessionStorage.getItem("PreviewNotification");
        if (id !== undefined && notificationFromPreview !== null) {
            const notificationObject = JSON.parse(notificationFromPreview!) as INotification;
            setInitialNotificationValues(deepCopy(notificationObject));
        } else {
            const getEvent = async (eventId: string) => {
                try {
                    setError(false);
                    setLoading(true);
                    const eventResponse = await getEventByNumber(eventId);
                    setInitialNotificationValues(setInitialValues(eventResponse));
                    setLoading(false);
                } catch (error: any) {
                    setError(true);
                    setLoading(false);
                    toast.error("ERROR: while getting Latest Review from ReviewID!", { style: { backgroundColor: "white", color: "red" } });
                    appInsights.trackException({
                        error,
                        severityLevel: SeverityLevel.Critical,
                        properties: {
                            versionId: eventId,
                        },
                    });
                }
            }
            if (id !== undefined) {
                getEvent(id);

            }
        }
    }, [])

    useEffect(() => {
        const getCameraInfo = async () => {
            if (initialNotificationValues.systemSourceId && initialNotificationValues.sourceId) {
                try {
                    setError(false);
                    setLoading(true);
                    const cameraResponse = await getCamera(initialNotificationValues.systemSourceId, initialNotificationValues.sourceId);

                    if (cameraResponse.isVideoAuthenticationRequired) {
                        const fromDivas = await getCameraFromDivas(cameraResponse.cameraId, cameraResponse.token);
                        initialNotificationValues.hlsUri = `${cameraResponse.videoHlsUri}${fromDivas}`;
                    } else {
                        initialNotificationValues.hlsUri = cameraResponse.videoHlsUri;
                    }
                    setInitialNotificationValues(initialNotificationValues);
                    setLoading(false);
                } catch (error: any) {
                    setLoading(false);
                    if (initialNotificationValues.sourceId === "0") {
                        toast.info("WARNING: Camera Info Not available.", { style: { backgroundColor: "white", color: "blue" } })
                    } else {
                        toast.error("ERROR: while getting Camera info. \n Disabling \"Include CCTV\" Property.", { style: { backgroundColor: "white", color: "red" } });
                    }
                    initialNotificationValues.includeCctv = false;
                    setInitialNotificationValues(initialNotificationValues);
                    appInsights.trackException({
                        error,
                        severityLevel: SeverityLevel.Critical,

                    });
                }
            } else if (initialNotificationValues.eventId  !== "" && (initialNotificationValues.sourceId === "")) {
                toast.info("WARNING: Camera Info Not available.", { style: { backgroundColor: "white", color: "blue" } })
            }
        }
        getCameraInfo();
    }, [initialNotificationValues.systemSourceId])

    const onSubmitHandler = async (
        values: INotification,
        actions: FormikHelpers<INotification>
    ) => {

        setError(false);
        setLoading(true);
        try {
            const valuesToJson = JSON.stringify(values);
            sessionStorage.setItem("PreviewNotification", valuesToJson);
            actions.setSubmitting(false);
            setLoading(false);
            navigate(`/events/${values.eventId}/preview`);
        } catch (error: any) {
            setError(true);
            setLoading(false);
            actions.setSubmitting(false);
            toast.error("ERROR: while submitting Notification!", { style: { backgroundColor: "white", color: "red" } });
            appInsights.trackException({
                error,
                id: `${id !== undefined
                    ? "Error in Create Notification"
                    : "Error in Create Notification"
                    }`,
                severityLevel: SeverityLevel.Critical,
                properties: {
                    versionId: values._id,
                },
            });
        }
    };

    if (loading) {
        return <LoadingImage></LoadingImage>;
    }

    if (error) {
        return <ErrorPage model="Add Notification" />;
    }

    return (
        <>
            <div className="container">
                <div className="page-header">
                    <h4>Add Notification</h4>
                </div>
                <Formik
                    initialValues={initialNotificationValues}
                    onSubmit={(values: any, actions: any) => { onSubmitHandler(values, actions); }}
                    enableReinitialize={true}>
                    {({ isSubmitting, errors, isValid, values, setValues, validateForm, setFieldValue }) => (
                        <Form>
                            <CustomCard headerText="Event Info" collapsible={true}>
                                <div>
                                    <div className="row">
                                        <div className="col text-center">
                                            <div className="form-group">
                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                    <Field
                                                        name="includeCctv"
                                                        id="includeCctv"
                                                        className="custom-control-input"
                                                        type="checkbox"
                                                        disabled={!values.cctvId || !values.hlsUri}
                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor="includeCctv"
                                                        title="includeCctv"
                                                    >
                                                        Include CCTV
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {values.includeCctv && (<div className="row mb-3">
                                        <div className="col text-center">
                                            <ReactPlayer
                                                url={values.hlsUri}
                                                controls
                                                playing
                                                width="100%"
                                                height="100%"
                                            />
                                        </div>
                                    </div>)}

                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label
                                                    htmlFor="confirmedDate"
                                                    title="confirmedDate"
                                                >
                                                    Time Of Incient
                                                </label>
                                                <Field
                                                    type="date"
                                                    name="confirmedDate"
                                                    id="confirmedDate"
                                                    title="confirmedDate"
                                                    placeholder="Time Of Incident"
                                                    className="form-control"
                                                    value={values.confirmedDate}
                                                    readOnly
                                                />
                                                <ErrorMessage
                                                    name="confirmedDate"
                                                    render={renderErrorMessage}
                                                ></ErrorMessage>
                                            </div>
                                            <div className="form-group">
                                                <label
                                                    htmlFor="location"
                                                    title="Location Description"
                                                >
                                                    Location Description
                                                </label>
                                                <Field
                                                    type="text"
                                                    name="location"
                                                    id="location"
                                                    title="Location Description"
                                                    placeholder="Location Description"
                                                    className="form-control"
                                                    value={values.location}
                                                    readOnly
                                                />
                                                <ErrorMessage
                                                    name="location"
                                                    render={renderErrorMessage}
                                                ></ErrorMessage>
                                            </div>
                                            <div className="form-group">
                                                <label
                                                    htmlFor="mileMarker"
                                                    title="Mile Marker"
                                                >
                                                    Mile Marker
                                                </label>
                                                <Field
                                                    type="text"
                                                    name="mileMarker"
                                                    id="mileMarker"
                                                    title="Mile Marker"
                                                    placeholder="Mile Marker"
                                                    className="form-control"
                                                    value={values.mileMarker}
                                                    readOnly
                                                />
                                                <ErrorMessage
                                                    name="mileMarker"
                                                    render={renderErrorMessage}
                                                ></ErrorMessage>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">

                                            <div className="form-group">
                                                <label
                                                    htmlFor="facilityStatus"
                                                    title="Facility Status"
                                                >
                                                    Facility Status
                                                </label>
                                                <Field
                                                    type="text"
                                                    name="facilityStatus"
                                                    id="facilityStatus"
                                                    title="Facility Status"
                                                    placeholder="Facility Status"
                                                    className="form-control"
                                                    value={values.facilityStatus}
                                                    readOnly
                                                />
                                                <ErrorMessage
                                                    name="facilityStatus"
                                                    render={renderErrorMessage}
                                                ></ErrorMessage>
                                            </div>
                                            <div className="form-group">
                                                <label
                                                    htmlFor="durationOfClosure"
                                                    title="Duration Of Closure"
                                                >
                                                    Duration Of Closure
                                                </label>
                                                <Field
                                                    type="text"
                                                    name="durationOfClosure"
                                                    id="durationOfClosure"
                                                    title="Duration Of Closure"
                                                    placeholder="Duration Of Closure"
                                                    className="form-control"
                                                    value={values.durationOfClosure}
                                                    readOnly
                                                />
                                                <ErrorMessage
                                                    name="durationOfClosure"
                                                    render={renderErrorMessage}
                                                ></ErrorMessage>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CustomCard>
                            <CustomCard headerText="Details to Provide" collapsible={true}>
                                <div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="documentType" title="Status" className="requiredField">
                                                    Status
                                                </label>
                                                <Field
                                                    as="select"
                                                    name="status"
                                                    className="form-control custom-select"
                                                    id="status"
                                                    title="Status"
                                                    value={values.status}
                                                    validate={(status: string) => {
                                                        if (isNullOrUndefinedOrWhiteSpace(status)) {
                                                            return "Status is required";
                                                        }
                                                        return null;
                                                    }}
                                                >
                                                    <option value="">Choose a Status</option>
                                                    <option value="New">New</option>
                                                    <option value="Update">Update</option>
                                                    <option value="Cleared">Cleared</option>
                                                </Field>
                                                <ErrorMessage
                                                    name="status"
                                                    render={renderErrorMessage}
                                                ></ErrorMessage>
                                            </div>
                                            <div className="form-group">
                                                <label
                                                    htmlFor="fatalities"
                                                    title="Fatalities"
                                                    className="requiredField"
                                                >
                                                    Fatalities
                                                </label>
                                                <Field
                                                    type="text"
                                                    name="fatalities"
                                                    id="fatalities"
                                                    title="Fatalities"
                                                    placeholder="Fatalities"
                                                    className="form-control"
                                                    value={values.fatalities}
                                                    validate={(fatalities: string) => {
                                                        if (isNullOrUndefinedOrWhiteSpace(fatalities)) {
                                                            return "Fatalities is required";
                                                        }
                                                        return null;
                                                    }}
                                                />
                                                <ErrorMessage
                                                    name="fatalities"
                                                    render={renderErrorMessage}
                                                ></ErrorMessage>
                                            </div>
                                            <div className="form-group">
                                                <label
                                                    htmlFor="injuries"
                                                    title="Injuries"
                                                    className="requiredField"
                                                >
                                                    Injuries
                                                </label>
                                                <Field
                                                    type="text"
                                                    name="injuries"
                                                    id="injuries"
                                                    title="Injuries"
                                                    placeholder="Injuries"
                                                    className="form-control"
                                                    value={values.injuries}
                                                    validate={(injuries: string) => {
                                                        if (isNullOrUndefinedOrWhiteSpace(injuries)) {
                                                            return "Injuries is required";
                                                        }
                                                        return null;
                                                    }}
                                                />
                                                <ErrorMessage
                                                    name="injuries"
                                                    render={renderErrorMessage}
                                                ></ErrorMessage>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="notificationType" title="Notification Type" className="requiredField">
                                                    Notification Type
                                                </label>
                                                <Field
                                                    as="select"
                                                    name="notificationType"
                                                    className="form-control custom-select"
                                                    id="notificationType"
                                                    title="Notification Type"
                                                    value={values.notificationType}
                                                    validate={(notificationType: string) => {
                                                        if (isNullOrUndefinedOrWhiteSpace(notificationType)) {
                                                            return "Notification Type is required";
                                                        }
                                                        return null;
                                                    }}
                                                >
                                                    <option value="Executive Notification">Executive Notification</option>
                                                    <option value="Severe Incident">Severe Incident</option>
                                                    <option value="Imminent Hazard">Imminent Hazard</option>
                                                </Field>
                                                <ErrorMessage
                                                    name="notificationType"
                                                    render={renderErrorMessage}
                                                ></ErrorMessage>
                                            </div>
                                            <div className="form-group">
                                                <label
                                                    htmlFor="numberOfVehicles"
                                                    title="Number Of Vehicles"
                                                    className="requiredField"
                                                >
                                                    Number Of Vehicles
                                                </label>
                                                <Field
                                                    type="text"
                                                    name="numberOfVehicles"
                                                    id="numberOfVehicles"
                                                    title="Number Of Vehicles"
                                                    placeholder="Number Of Vehicles"
                                                    className="form-control"
                                                    value={values.numberOfVehicles}
                                                    validate={(numberOfVehicles: string) => {
                                                        if (isNullOrUndefinedOrWhiteSpace(numberOfVehicles)) {
                                                            return "Number Of Vehicles is required";
                                                        }
                                                        return null;
                                                    }}
                                                />
                                                <ErrorMessage
                                                    name="numberOfVehicles"
                                                    render={renderErrorMessage}
                                                ></ErrorMessage>
                                            </div>
                                            <div className="form-group">
                                                <label
                                                    htmlFor="typeOfVehicles"
                                                    title="Type Of Vehicles"
                                                    className="requiredField"
                                                >
                                                    Type Of Vehicles
                                                </label>
                                                <Field
                                                    type="text"
                                                    name="typeOfVehicles"
                                                    id="typeOfVehicles"
                                                    title="Type Of Vehicles"
                                                    placeholder="Type Of Vehicles"
                                                    className="form-control"
                                                    value={values.typeOfVehicles}
                                                    validate={(typeOfVehicles: string) => {
                                                        if (isNullOrUndefinedOrWhiteSpace(typeOfVehicles)) {
                                                            return "Type Of Vehicles is required";
                                                        }
                                                        return null;
                                                    }}
                                                />
                                                <ErrorMessage
                                                    name="typeOfVehicles"
                                                    render={renderErrorMessage}
                                                ></ErrorMessage>
                                            </div>
                                            <div className="form-group">
                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                    <Field
                                                        name="isEmergency"
                                                        id="isEmergency"
                                                        className="custom-control-input"
                                                        type="checkbox"
                                                    // checked={values.isCustom ? true : false}
                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor="isEmergency"
                                                        title="Emergency"
                                                    >
                                                        Emergency
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <label
                                                    htmlFor="narrativeActions"
                                                    title="Narrative and Response Actions"
                                                    className="requiredField"
                                                >
                                                    Narrative and Response Actions
                                                </label>
                                                <Field
                                                    as="textarea"
                                                    name="narrativeActions"
                                                    id="narrativeActions"
                                                    title="Narrative and Response Actions"
                                                    placeholder="Narrative and Response Actions"
                                                    className="form-control"
                                                    rows="4"
                                                    value={values.narrativeActions}
                                                    validate={(narrativeActions: string) => {
                                                        if (isNullOrUndefinedOrWhiteSpace(narrativeActions)) {
                                                            return "Narrative Actions is required";
                                                        }
                                                        return null;
                                                    }}
                                                />
                                                <ErrorMessage
                                                    name="narrativeActions"
                                                    render={renderErrorMessage}
                                                ></ErrorMessage>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CustomCard>
                            <CustomCard headerText="Notification Reach" collapsible={true}>
                                <FieldArray
                                    name="notificationReach"

                                    render={arrayHelpers => (
                                        <>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <div className="form-check">
                                                            <input
                                                                name="selectedOptions"
                                                                type="checkbox"
                                                                value="99"
                                                                checked={values.notificationReach.includes("99")}
                                                                onChange={e => changeReachHelper(e, values, arrayHelpers)}
                                                                title="Central Office"
                                                                className="form-check-input"
                                                            />
                                                            <label
                                                                htmlFor="selectedOptions"
                                                                title="Central Office"
                                                                className="form-check-label fw-normal"
                                                            >
                                                                Central Office
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="form-check">
                                                            <input
                                                                name="selectedOptions"
                                                                type="checkbox"
                                                                value="d1"
                                                                checked={values.notificationReach.includes("d1")}
                                                                onChange={e => changeReachHelper(e, values, arrayHelpers)}
                                                                title="District 1"
                                                                className="form-check-input"
                                                            />
                                                            <label
                                                                htmlFor="selectedOptions"
                                                                title="District 1"
                                                                className="form-check-label fw-normal"
                                                            >
                                                                District 1
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="form-check">
                                                            <input
                                                                name="selectedOptions"
                                                                type="checkbox"
                                                                value="d2"
                                                                checked={values.notificationReach.includes("d2")}
                                                                onChange={e => changeReachHelper(e, values, arrayHelpers)}
                                                                title="District 2"
                                                                className="form-check-input"
                                                            />
                                                            <label
                                                                htmlFor="selectedOptions"
                                                                title="District 2"
                                                                className="form-check-label fw-normal"
                                                            >
                                                                District 2
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="form-check">
                                                            <input
                                                                name="selectedOptions"
                                                                type="checkbox"
                                                                value="d3"
                                                                checked={values.notificationReach.includes("d3")}
                                                                onChange={e => changeReachHelper(e, values, arrayHelpers)}
                                                                title="District 3"
                                                                className="form-check-input"
                                                            />
                                                            <label
                                                                htmlFor="selectedOptions"
                                                                title="District 3"
                                                                className="form-check-label fw-normal"
                                                            >
                                                                District 3
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="form-check">
                                                            <input
                                                                name="selectedOptions"
                                                                type="checkbox"
                                                                value="tp"
                                                                checked={values.notificationReach.includes("tp")}
                                                                onChange={e => changeReachHelper(e, values, arrayHelpers)}
                                                                title="Turnpike"
                                                                className="form-check-input"
                                                            />
                                                            <label
                                                                htmlFor="selectedOptions"
                                                                title="Turnpike"
                                                                className="form-check-label fw-normal"
                                                            >
                                                                Turnpike
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <div className="form-check">
                                                            <input
                                                                name="selectedOptions"
                                                                type="checkbox"
                                                                value="d4"
                                                                checked={values.notificationReach.includes("d4")}
                                                                onChange={e => changeReachHelper(e, values, arrayHelpers)}
                                                                title="District 4"
                                                                className="form-check-input"
                                                            />
                                                            <label
                                                                htmlFor="selectedOptions"
                                                                title="District 4"
                                                                className="form-check-label fw-normal"
                                                            >
                                                                District 4
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="form-check">
                                                            <input
                                                                name="selectedOptions"
                                                                type="checkbox"
                                                                value="d5"
                                                                checked={values.notificationReach.includes("d5")}
                                                                onChange={e => changeReachHelper(e, values, arrayHelpers)}
                                                                title="District 5"
                                                                className="form-check-input"
                                                            />
                                                            <label
                                                                htmlFor="selectedOptions"
                                                                title="District 5"
                                                                className="form-check-label fw-normal"
                                                            >
                                                                District 5
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="form-check">
                                                            <input
                                                                name="selectedOptions"
                                                                type="checkbox"
                                                                value="d6"
                                                                checked={values.notificationReach.includes("d6")}
                                                                onChange={e => changeReachHelper(e, values, arrayHelpers)}
                                                                title="District 6"
                                                                className="form-check-input"
                                                            />
                                                            <label
                                                                htmlFor="selectedOptions"
                                                                title="District 6"
                                                                className="form-check-label fw-normal"
                                                            >
                                                                District 6
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="form-check">
                                                            <input
                                                                name="selectedOptions"
                                                                type="checkbox"
                                                                value="d7"
                                                                checked={values.notificationReach.includes("d7")}
                                                                onChange={e => changeReachHelper(e, values, arrayHelpers)}
                                                                title="District 7"
                                                                className="form-check-input"
                                                            />
                                                            <label
                                                                htmlFor="selectedOptions"
                                                                title="District 7"
                                                                className="form-check-label fw-normal"
                                                            >
                                                                District 7
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                />
                                {values.notificationReach.length === 0 && (
                                    <h6 style={errorMessageStyle}><b>ERROR:</b> Please select one or more Offices.</h6>
                                )}
                            </CustomCard>
                            {!user?.isAdmin && (
                                <div className="row">
                                    <div className="col text-center">
                                        <p>
                                            Only Administrators can send Notifications
                                        </p>
                                    </div>
                                </div>
                            )}
                            <div className="row">
                                <div className="col text-center">
                                    {user?.isAdmin && (<button
                                        id="submit"
                                        name="submit"
                                        type="submit"
                                        disabled={isSubmitting || values.notificationReach.length === 0}
                                        className="btn btn-primary"
                                        title="Submit"
                                    >
                                        <FontAwesomeIcon icon="check-circle" />{" "}
                                        {id !== undefined ? "Update" : "Submit"}
                                    </button>)}

                                    <button
                                        id="cancel"
                                        name="cancel"
                                        type="button"
                                        title="Cancel"
                                        className="btn btn-danger"
                                        onClick={() => navigate("/events")}
                                    >
                                        <FontAwesomeIcon icon="times-circle" /> {user?.isAdmin ? "Cancel" : "Back"}
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <FormValidationSummary errors={errors} isValid={isValid} />
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </>)
}

export default AddNotification
