import React, { useState, useEffect, CSSProperties } from "react";
import { Collapse } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";

export enum ExpandIconLocation {
  farLeft = "farLeft",
  justLeftOfText = "justLeftOfText",
  justRightOfText = "justRightOfText",
  farRight = "farRight",
}

export interface ICustomCardProps {
  headerText?: string;
  additionalClass?: string;
  collapsible?: boolean;
  defaultClosed?: boolean;
  expandIconLocation?: ExpandIconLocation;
  expandIconSize?: SizeProp;
  onToggleChange?: (isOpen: boolean) => void;
  badgeValue?: string;
  badgeType?: string;
  modalStyle?: boolean;
}

const scrollableStyle: CSSProperties = {
  maxHeight: "35vh",
  overflowY: "auto",
};

/** A Bootstrap Card with collapsing funcionality */
export const CustomCard: React.FC<ICustomCardProps> = (props) => {
  const [isOpen, setIsOpen] = useState(props.defaultClosed !== true);

  useEffect(() => {
    setIsOpen(props.defaultClosed !== true);
  }, [props.defaultClosed]);

  const defaultIconLocation = ExpandIconLocation.farRight;
  const defaultIconSize = "1x";

  const toggleOpen = () => {
    const targetIsOpen = !isOpen;
    setIsOpen(targetIsOpen);
    if (props.onToggleChange) {
      props.onToggleChange(targetIsOpen);
    }
  };

  const getIconLocation = (): ExpandIconLocation => {
    return props.expandIconLocation !== undefined
      ? props.expandIconLocation
      : defaultIconLocation;
  };

  const getIconSize = (): SizeProp => {
    return props.expandIconSize !== undefined
      ? props.expandIconSize
      : defaultIconSize;
  };

  const getClass = (): string => {
    return props.additionalClass ? `card ${props.additionalClass}` : "card";
  };

  const getStyle = (): CSSProperties | undefined => {
    return props.modalStyle ? scrollableStyle : undefined;
  };

  const getExpandCollapseIcons = () => {
    if (props.collapsible !== true) {
      return null;
    }
    const location = getIconLocation();
    const iconClass: string =
      location === ExpandIconLocation.farLeft
        ? "iconBtn float-left"
        : location === ExpandIconLocation.farRight
          ? "iconBtn float-right"
          : "iconBtn";

    return isOpen === true ? (
      <button
        type="button"
        title="Collapse"
        className={iconClass}
        aria-label="Collapse Section"
        onClick={() => toggleOpen()}
      >
        <FontAwesomeIcon icon="minus-circle" size={getIconSize()} />
      </button>
    ) : (
      <button
        type="button"
        title="Expand"
        className={iconClass}
        aria-label="Expand Section"
        onClick={() => toggleOpen()}
      >
        <FontAwesomeIcon icon="plus-circle" size={getIconSize()} />
      </button>
    );
  };

  const getHeader = () => {
    if (props.headerText === undefined) {
      return null;
    }

    const location = getIconLocation();
    const badgeContent =
      props.badgeValue !== undefined ? (
        <span
          className={
            props.badgeType !== undefined
              ? `badge badge-${props.badgeType} mx-2`
              : "badge mx-2"
          }
        >
          {props.badgeValue}
        </span>
      ) : null;

    const headerContent =
      location === ExpandIconLocation.farLeft ||
        location === ExpandIconLocation.justLeftOfText ? (
        <>
          {getExpandCollapseIcons()}
          {/* <span
            tabIndex={0}
            title={props.headerText}
            style={{ fontWeight: "bold", fontSize: "1.15em" }}
          > */}
          {props.headerText}
          {/* </span> */}
          {badgeContent}
        </>
      ) : (
        <>
          {/* <span
            tabIndex={0}
            title={props.headerText}
            style={{ fontWeight: "bold", fontSize: "1.15em" }}
          > */}
          {props.headerText}
          {/* </span> */}
          {badgeContent}
          {getExpandCollapseIcons()}
        </>
      );

    const cardStyles: CSSProperties = {
      // backgroundColor: "#33557C"
    };

    return (
      <div
        className="card-header text-center"
        style={{ padding: 5, fontSize: 18, ...cardStyles }}
        onClick={() => toggleOpen()}
      >
        {headerContent}
      </div>
    );
  };

  const getBody = () => {
    return (
      <div className="card-body" style={getStyle()}>
        {props.children}
      </div>
    );
  };

  return (
    <>
      <div className={getClass()}>
        {props.headerText !== undefined ? getHeader() : null}
        {props.collapsible === true ? (
          <Collapse isOpen={isOpen}>{getBody()}</Collapse>
        ) : (
          getBody()
        )}
      </div>
      <br />
    </>
  );
};
