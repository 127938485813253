interface IProps {
    model: string;
}

const ErrorPage = (props: IProps) => {
    return (
        <h3>
            Could not load {props.model}. Please try again and contact support staff
            if this error persists.
        </h3>
    );
};

export default ErrorPage;
