import React, { useState } from "react";
import { useNavigate } from "react-router";
import { INotification } from "../../../../shared/models/INotification";
import {
    Formik,
    Form,
    Field,
    ErrorMessage,
    FormikHelpers,
    FieldArray,
    FieldArrayRenderProps,
} from "formik";
import { toast } from "react-toastify";
import { appInsights } from "../../utils/Initializer";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { CustomCard } from "../common/CustomCard";
import { isNullOrUndefinedOrWhiteSpace } from "../../utils/stringUtils";
import { renderErrorMessage } from "../common/formik/ValidationHelpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormValidationSummary } from "../common/validationSummary";
import { RootState } from "../../redux/RootReducer";
import { useSelector } from "react-redux";

const CustomNotification: React.FC = () => {
    let navigate = useNavigate();

    const { user } = useSelector(
        (state: RootState) => state.userReducer
    );

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const setInitialValues = (): INotification => {

        const today = new Date();
        const custom = {
            isCustom: true,
            signature: "",
            district: "",
            isEmergency: false,
            title: "Custom Notification",
            includeCctv: false,
            cctvId: "",
            eventId: "",
            location: "",
            hlsUri: "",
            incidentType: "",
            confirmedDate: "",
            confirmedTime: "",
            durationOfClosure: "",
            facilityStatus: "",
            fatalities: "",
            injuries: "",
            mileMarker: "",
            latitude: 0,
            longitude: 0,
            numberOfVehicles: "",
            typeOfVehicles: "",
            status: "",
            createdAt: today,
            subject: "",
            narrativeActions: "",
            sourceId: "",
            systemSourceId: "",
            notificationReach: [user?.district!],
            notificationType: "Executive Notification",
            archived: false
        };

        const notificationFromCustomPreview = sessionStorage.getItem("PreviewCustomNotification");
        if (notificationFromCustomPreview !== null) {
            const notificationObject = JSON.parse(notificationFromCustomPreview!) as INotification;
            custom.isEmergency = notificationObject.isEmergency;
            custom.status = notificationObject.status;
            custom.narrativeActions = notificationObject.narrativeActions;
            custom.notificationReach = notificationObject.notificationReach;
            custom.notificationType = notificationObject.notificationType;
        }

        return custom;
    };
    const [initialNotificationValues, setInitialNotificationValues] = useState<INotification>(
        setInitialValues()
    );

    const changeReachHelper = (e: React.ChangeEvent<HTMLInputElement>, notification: INotification, arrayHelpers: FieldArrayRenderProps) => {
        if (e.target.checked) arrayHelpers.push(e.target.value);
        else {
            const idx = notification.notificationReach.indexOf(e.target.value);
            arrayHelpers.remove(idx);
        }
    }

    const onSubmitHandler = async (
        values: INotification,
        actions: FormikHelpers<INotification>
    ) => {
        setError(false);
        setLoading(true);
        try {
            const valuesToJson = JSON.stringify(values);
            sessionStorage.setItem("PreviewCustomNotification", valuesToJson);
            actions.setSubmitting(false);
            setLoading(false);
            navigate(`/custom/preview`);
        } catch (error: any) {
            setError(true);
            actions.setSubmitting(false);
            toast.error("Error: while submitting Notification!", { style: { backgroundColor: "white", color: "red" } });
            appInsights.trackException({
                error,
                id: `${"Error in Create Notification"}`,
                severityLevel: SeverityLevel.Critical,
                properties: {
                    versionId: values._id,
                },
            });
        }
    };

    return (
        <>
            <div className="container">
                <div className="page-header">
                    <h4>Add Custom Notification</h4>
                </div>
                <Formik
                    initialValues={initialNotificationValues}
                    onSubmit={(values: any, actions: any) => { onSubmitHandler(values, actions); }}
                    enableReinitialize={true}>
                    {({ isSubmitting, errors, isValid, values, setValues, validateForm, setFieldValue }) => (
                        <Form>
                            <CustomCard headerText="Custom Info" collapsible={false}>
                                <div>
                                    <div className="row justify-content-center">
                                        <div className="col-1">
                                            <div className="form-group">
                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                    <Field
                                                        name="isEmergency"
                                                        id="isEmergency"
                                                        className="custom-control-input"
                                                        type="checkbox"
                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor="isEmergency"
                                                        title="Emergency"
                                                    >
                                                        Emergency
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label htmlFor="documentType" title="Status" className="requiredField">
                                                    Status
                                                </label>
                                                <Field
                                                    as="select"
                                                    name="status"
                                                    className="form-control custom-select"
                                                    id="status"
                                                    title="Status"
                                                    value={values.status}
                                                    validate={(status: string) => {
                                                        if (isNullOrUndefinedOrWhiteSpace(status)) {
                                                            return "Status is required";
                                                        }
                                                        return null;
                                                    }}
                                                >
                                                    <option value="">Choose a Status</option>
                                                    <option value="New">New</option>
                                                    <option value="Update">Update</option>
                                                    <option value="Cleared">Cleared</option>
                                                </Field>
                                                <ErrorMessage
                                                    name="status"
                                                    render={renderErrorMessage}
                                                ></ErrorMessage>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label htmlFor="notificationType" title="Notification Type" className="requiredField">
                                                    Notification Type
                                                </label>
                                                <Field
                                                    as="select"
                                                    name="notificationType"
                                                    className="form-control custom-select"
                                                    id="notificationType"
                                                    title="Notification Type"
                                                    value={values.notificationType}
                                                    validate={(notificationType: string) => {
                                                        if (isNullOrUndefinedOrWhiteSpace(notificationType)) {
                                                            return "Notification Type is required";
                                                        }
                                                        return null;
                                                    }}
                                                >
                                                    <option value="Executive Notification">Executive Notification</option>
                                                    <option value="Severe Incident">Severe Incident</option>
                                                    <option value="Imminent Hazard">Imminent Hazard</option>
                                                </Field>
                                                <ErrorMessage
                                                    name="notificationType"
                                                    render={renderErrorMessage}
                                                ></ErrorMessage>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <label
                                                    htmlFor="narrativeActions"
                                                    title="Narrative and Response Actions"
                                                    className="requiredField"
                                                >
                                                    Narrative and Response Actions
                                                </label>
                                                <Field
                                                    as="textarea"
                                                    name="narrativeActions"
                                                    id="narrativeActions"
                                                    title="narrativeActions"
                                                    placeholder="Narrative and Response Actions"
                                                    className="form-control"
                                                    rows="4"
                                                    value={values.narrativeActions}
                                                    validate={(narrativeActions: string) => {
                                                        if (isNullOrUndefinedOrWhiteSpace(narrativeActions)) {
                                                            return "Narrative Actions is required";
                                                        }
                                                        return null;
                                                    }}
                                                />
                                                <ErrorMessage
                                                    name="narrativeActions"
                                                    render={renderErrorMessage}
                                                ></ErrorMessage>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CustomCard>
                            <CustomCard headerText="Notification Reach" collapsible={true}>
                                <FieldArray
                                    name="notificationReach"

                                    render={arrayHelpers => (
                                        <>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <div className="form-check">
                                                            <input
                                                                name="selectedOptions"
                                                                type="checkbox"
                                                                value="99"
                                                                checked={values.notificationReach.includes("99")}
                                                                onChange={e => changeReachHelper(e, values, arrayHelpers)}
                                                                title="Central Office"
                                                                className="form-check-input"
                                                            />
                                                            <label
                                                                htmlFor="selectedOptions"
                                                                title="Central Office"
                                                                className="form-check-label fw-normal"
                                                            >
                                                                Central Office
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="form-check">
                                                            <input
                                                                name="selectedOptions"
                                                                type="checkbox"
                                                                value="d1"
                                                                checked={values.notificationReach.includes("d1")}
                                                                onChange={e => changeReachHelper(e, values, arrayHelpers)}
                                                                title="District 1"
                                                                className="form-check-input"
                                                            />
                                                            <label
                                                                htmlFor="selectedOptions"
                                                                title="District 1"
                                                                className="form-check-label fw-normal"
                                                            >
                                                                District 1
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="form-check">
                                                            <input
                                                                name="selectedOptions"
                                                                type="checkbox"
                                                                value="d2"
                                                                checked={values.notificationReach.includes("d2")}
                                                                onChange={e => changeReachHelper(e, values, arrayHelpers)}
                                                                title="District 2"
                                                                className="form-check-input"
                                                            />
                                                            <label
                                                                htmlFor="selectedOptions"
                                                                title="District 2"
                                                                className="form-check-label fw-normal"
                                                            >
                                                                District 2
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="form-check">
                                                            <input
                                                                name="selectedOptions"
                                                                type="checkbox"
                                                                value="d3"
                                                                checked={values.notificationReach.includes("d3")}
                                                                onChange={e => changeReachHelper(e, values, arrayHelpers)}
                                                                title="District 3"
                                                                className="form-check-input"
                                                            />
                                                            <label
                                                                htmlFor="selectedOptions"
                                                                title="District 3"
                                                                className="form-check-label fw-normal"
                                                            >
                                                                District 3
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="form-check">
                                                            <input
                                                                name="selectedOptions"
                                                                type="checkbox"
                                                                value="tp"
                                                                checked={values.notificationReach.includes("tp")}
                                                                onChange={e => changeReachHelper(e, values, arrayHelpers)}
                                                                title="TurnPike"
                                                                className="form-check-input"
                                                            />
                                                            <label
                                                                htmlFor="selectedOptions"
                                                                title="TurnPike"
                                                                className="form-check-label fw-normal"
                                                            >
                                                                TurnPike
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <div className="form-check">
                                                            <input
                                                                name="selectedOptions"
                                                                type="checkbox"
                                                                value="d4"
                                                                checked={values.notificationReach.includes("d4")}
                                                                onChange={e => changeReachHelper(e, values, arrayHelpers)}
                                                                title="District 4"
                                                                className="form-check-input"
                                                            />
                                                            <label
                                                                htmlFor="selectedOptions"
                                                                title="District 4"
                                                                className="form-check-label fw-normal"
                                                            >
                                                                District 4
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="form-check">
                                                            <input
                                                                name="selectedOptions"
                                                                type="checkbox"
                                                                value="d5"
                                                                checked={values.notificationReach.includes("d5")}
                                                                onChange={e => changeReachHelper(e, values, arrayHelpers)}
                                                                title="District 5"
                                                                className="form-check-input"
                                                            />
                                                            <label
                                                                htmlFor="selectedOptions"
                                                                title="District 5"
                                                                className="form-check-label fw-normal"
                                                            >
                                                                District 5
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="form-check">
                                                            <input
                                                                name="selectedOptions"
                                                                type="checkbox"
                                                                value="d6"
                                                                checked={values.notificationReach.includes("d6")}
                                                                onChange={e => changeReachHelper(e, values, arrayHelpers)}
                                                                title="District 6"
                                                                className="form-check-input"
                                                            />
                                                            <label
                                                                htmlFor="selectedOptions"
                                                                title="District 6"
                                                                className="form-check-label fw-normal"
                                                            >
                                                                District 6
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="form-check">
                                                            <input
                                                                name="selectedOptions"
                                                                type="checkbox"
                                                                value="d7"
                                                                checked={values.notificationReach.includes("d7")}
                                                                onChange={e => changeReachHelper(e, values, arrayHelpers)}
                                                                title="District 7"
                                                                className="form-check-input"
                                                            />
                                                            <label
                                                                htmlFor="selectedOptions"
                                                                title="District 7"
                                                                className="form-check-label fw-normal"
                                                            >
                                                                District 7
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                />
                            </CustomCard>
                            <div className="row">
                                <div className="col text-center">
                                    <button
                                        id="submit"
                                        name="submit"
                                        type="submit"
                                        disabled={isSubmitting || values.notificationReach.length === 0}
                                        className="btn btn-primary"
                                        title="Submit"
                                    >
                                        <FontAwesomeIcon icon="check-circle" />{" "}
                                        Submit
                                    </button>
                                    <button
                                        id="cancel"
                                        name="cancel"
                                        type="button"
                                        title="Cancel"
                                        className="btn btn-danger"
                                        onClick={() => navigate("/events")}
                                    >
                                        <FontAwesomeIcon icon="times-circle" /> Cancel
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <FormValidationSummary errors={errors} isValid={isValid} />
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>

            </div >
        </>
    );
}

export default CustomNotification;