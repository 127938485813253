
interface IProps {
    error: string | undefined | null;
}

export const ValidationMessage = (props: IProps) => {
    if (props.error === undefined || props.error === null) {
        return null;
    }
    return (
        <div className="text-danger">
            <strong>ERROR:</strong> {props.error}
        </div>
    );
};
