import { CSSProperties, useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { INotification } from "../../../../shared/models/INotification"
import { createNotification } from "../../apis/NotificationApi"
import { toast } from "react-toastify"
import moment from "moment"
import TSMO from "../../assets/logos/tsm&o-white.png";
import { LoadingImage } from "../common/LoadingImage";

const CustomNotificationPreview: React.FC = () => {
    const logoContainerStyle: CSSProperties = {
        borderRadius: "5px 5px 0px 0px",
        backgroundColor: "#006BD6",
        maxWidth: "550px",
        margin: "0px"
    }
    const previewContainerStyle: CSSProperties = {
        margin: "0px",
        padding: "7px 0px",
        borderRadius: 5,
        backgroundColor: "whitesmoke",
        overflow: "hidden",
        maxWidth: "550px"
    }
    const previewBodyStyle: CSSProperties = {
        padding: "15px",
        borderRadius: 5,
        backgroundColor: "#B0C4DE",
        overflow: "hidden",
        maxWidth: "550px"
    }

    const statusHeader: CSSProperties = {
        marginLeft: "30px",
    }



    let navigate = useNavigate();

    const [notification, setNotification] = useState<INotification | null>(null);
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        try {
            const previewData = sessionStorage.getItem("PreviewCustomNotification");
            const notificationObject = JSON.parse(previewData!) as INotification;
            setNotification(notificationObject!);

        } catch (error: any) {
            console.log(error)
        }
    }, [])

    const submitNotification = () => {
        setSubmitting(true);
        if (notification?.isEmergency) {
            notification.notificationReach.push("em")
        }

        const today = new Date();
        notification!.createdAt = today;
        notification!.createdDate = today;

        createNotification(notification!)
            .then(res => {
                sessionStorage.removeItem("PreviewCustomNotification");
                toast.success("Custom Notification Created.");
                navigate(`/notifications`);
            })
            .catch(err => {
                toast.error("ERROR: while submitting Custom Notification!", { style: { backgroundColor: "white", color: "red" } });
                console.log(err)
                setSubmitting(false);
            });
    }

    const previousView = () => {
        navigate(`/custom`);
    }


    return (<>
        <div className="row justify-content-center mt-4 mb-0">
            <div className="col-12" style={logoContainerStyle}>
                <img style={{ width: "180px" }} src={TSMO} alt="TSMO Logo" />
            </div >
        </div >
        <div className="row justify-content-center">
            <div className="col-12" style={previewContainerStyle}>
                <h4 style={statusHeader}>NEW</h4>
                <div className="row justify-content-center m-4" >
                    <div className="col-12" style={previewBodyStyle}>
                        <h4>This is a Custom Notification</h4>
                        <h6>Subject: {notification?.narrativeActions}</h6>
                        <h6>Live Video Stream Not Available.</h6>
                        {submitting ? (<LoadingImage></LoadingImage>) : (<div className="row justify-content-left mt-3" >
                            <div className="col-8"><button className="btn btn-light btn-lg" onClick={previousView}>PREVIOUS</button></div>
                            <div className="col-4"><button className="btn btn-primary btn-lg" onClick={submitNotification}>SUBMIT</button></div>
                        </div>)}
                    </div>
                </div >
            </div>
        </div>
    </>);
}

export default CustomNotificationPreview;
