import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/RootReducer';

const LogOut: React.FC = () => {
    const { isAuthenticated, user} = useSelector((state: RootState) => state.userReducer);
    const logoutUrl = isAuthenticated && user?.external ? `${fdot.process.env.BACKEND_SERVER_HOST}/logoutB2c` : `${fdot.process.env.BACKEND_SERVER_HOST}/logout`;

    return (
        <a className='nav-link' href={logoutUrl}>Sign Out</a>
    );
};

export default LogOut;