import React, { CSSProperties } from "react";
import "../../../App.css"

interface IProps {
    thProps: any;
}

// export const headerFontSize: CSSProperties = { fontSize: "10px" };

export const CustomHeaderCell = (props: IProps) => {
    return <a {...props.thProps} title={props.thProps.children[0].props.children[0].props.children} className="centeredColumn" placeholder={props.thProps.children[0].props.children[0].props.children} />;
};
