import { combineReducers } from "@reduxjs/toolkit";
import configReducer from './slices/ConfigSlice';
import profileReducer from './slices/ProfileSlice';
import userReducer from './slices/UserSlice';
import { userApi } from "./slices/apis/UserApi";
import searchReducer from "./slices/searchSlice";

const rootReducer = combineReducers({
    configReducer,
    profileReducer,
    userReducer,
    searchReducer,
    [userApi.reducerPath]: userApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
