import axios from "axios";

//This is the WebApp backend API.
const eventsUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/api/events`;

export const getEvents = async (skip: number, take: number) => {
  const response = await axios.get(`${eventsUrl}?skip=${skip}&take=${take}`);
  return response.data;
};

export const getLatestEvents = async () => {
  const response = await axios.get(`${eventsUrl}/latest`);
  return response.data;
};

export const getEventsByEventId = async (eventId: string) => {
  const response = await axios.get(`${eventsUrl}/eventsByEventId/${eventId}`);
  return response.data;
};

export const getEventById = async (id: string) => {
  const response = await axios.get(`${eventsUrl}/${id}`);
  return response.data;
};

export const getEventByNumber = async (eventNumber: string) => {
  const response = await axios.get(`${eventsUrl}/byNumber/${eventNumber}`);
  return response.data;
};
