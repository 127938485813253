import React, { Suspense } from "react";
import Loading from "react-loading";
import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { RootState } from "../../redux/RootReducer";
import NotificationsList from "../notifications/notificationsList";
import EventsList from "../events/eventsList";
import AddNotification from "../notifications/addNotification";
import NotificationPreview from "../notifications/notificationPreview";
import CustomNotification from "../notifications/customNotification";
import CustomNotificationPreview from "../notifications/customNotificationPreview";

// const Home = React.lazy(() => import("../Home"));
const Home = React.lazy(() => import("../eventNotifcations/event.index"));
const Users = React.lazy(() => import("../eventNotifcations/index")); // TODO : create component and change path
const NotAuthorized = React.lazy(() => import("../NotAuthorized"));
const NoMatch = React.lazy(() => import("../NoMatch"));
const Staff = React.lazy(() => import("../staff/Staff"));
const Impersonate = React.lazy(() => import("../Impersonate"));
const LoginFailure = React.lazy(() => import("../LoginFailure"));

const StaffAuthenticatedRoutes = () => [
  <Route path="/Staff" element={<Staff />} key={"Staff"} />,
  <Route path="/Impersonate" element={<Impersonate />} key={"Impersonate"} />,
];

const AuthenticatedRoutes: React.FC = () => {
  const { user } = useSelector((state: RootState) => state.userReducer);
  return (
    <Suspense fallback={<Loading></Loading>}>
      <Routes>
        <Route path="/" element={<EventsList />} key={"Home"} />
        <Route path="/" element={<Users />} key={"Users"} />
        <Route path="/NotAuthorized" element={<NotAuthorized />} key={"NotAuthorized"} />
        <Route path="/LoginFailure" element={<LoginFailure />} key={"LoginFailure"} />
        <Route path="/notifications" element={<NotificationsList />} key={"Notifications"} />
        <Route path="/notifications/:id" element={<AddNotification />} key={"Edit"} />
        <Route path="/notifications/:id/preview" element={<NotificationPreview />} key={"Preview"} />

        <Route path="/events" element={<EventsList />} key={"Events"} />
        <Route path="/events/:id" element={<AddNotification />} key={"Add"} />
        <Route path="/events/:id/preview" element={<NotificationPreview />} key={"Preview"} />

        <Route path="/custom" element={<CustomNotification />} key={"Custom Notification"} />
        <Route path="/custom/preview" element={<CustomNotificationPreview />} key={"Custom Notification Preview"} />

        {user && !user.external && StaffAuthenticatedRoutes()}
        <Route path="*" element={<NoMatch />} key={"NoMatch"} />
      </Routes>
    </Suspense>
  );
};

export default AuthenticatedRoutes;
